<!-- components/AppSideBarItem.vue -->
<script setup lang="ts">

const props = defineProps<{
  to: string
  img: string
  title: string
}>()

const localePath = useLocalePath()

</script>
<template>
  <li>
    <NuxtLinkLocale :to="to" class="dashboard-link" :class="{ active: $route.path === localePath(to) }">
      <slot name="icon">
        <img :src="img" loading="lazy" :alt="title" />
      </slot>
      <span>
        {{ title }}
      </span>
    </NuxtLinkLocale>
  </li>
</template>

<style scoped>
.dashboard-link {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #90a3bf;
  border-radius: 10px;
  padding: 0 15px;
  margin-bottom: 5px;
  transition: all 0.3s linear;
}
.dashboard-link.active,
.dashboard-link:hover {
  background: #2d3291;
  color: white;
  transition: all 0.3s linear;
}
</style>
